// Generated by Framer (aa1abb7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["EfcFBdZXw", "jPz9WwxEu", "G7sh9WltN"];

const serializationHash = "framer-N50eg"

const variantClassNames = {EfcFBdZXw: "framer-v-1vatqyt", G7sh9WltN: "framer-v-1u9oeux", jPz9WwxEu: "framer-v-9vd1pc"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {delay: 0, duration: 4, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {Complete: "jPz9WwxEu", Inactive: "G7sh9WltN", Playing: "EfcFBdZXw"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "EfcFBdZXw"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "EfcFBdZXw", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppeary94pnt = activeVariantCallback(async (...args) => {
setVariant("jPz9WwxEu")
})

useOnVariantChange(baseVariant, {default: onAppeary94pnt, G7sh9WltN: undefined})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({jPz9WwxEu: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1vatqyt", className, classNames)} data-framer-name={"Playing"} data-highlight layoutDependency={layoutDependency} layoutId={"EfcFBdZXw"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-8870a9c4-2a9d-46c2-84fc-3470fbec519e, rgba(0, 0, 0, 0.05))", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, boxShadow: "0px 1px 20px 0px var(--token-3f8a7e6c-c0c4-4adb-9b50-34cd4c8d76d7, rgb(64, 120, 168))", ...style}} {...addPropertyOverrides({G7sh9WltN: {"data-framer-name": "Inactive", "data-highlight": undefined}, jPz9WwxEu: {"data-framer-name": "Complete"}}, baseVariant, gestureVariant)}><motion.div className={"framer-ofkeuz"} data-framer-name={"Indicator"} layoutDependency={layoutDependency} layoutId={"OW1zazoZ6"} style={{background: "linear-gradient(268deg, var(--token-5e357ad1-f56a-4886-be0b-ea13c633f85c, rgba(184, 199, 217, 0.5)) /* {\"name\":\"grey blue\"} */ 0%, rgba(0, 0, 0, 0) 100%)", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8}} variants={{jPz9WwxEu: {background: "linear-gradient(270deg, var(--token-5e357ad1-f56a-4886-be0b-ea13c633f85c, rgba(184, 199, 217, 0.5)) /* {\"name\":\"grey blue\"} */ 0%, rgba(0, 0, 0, 0) 100%)"}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-N50eg.framer-knkg47, .framer-N50eg .framer-knkg47 { display: block; }", ".framer-N50eg.framer-1vatqyt { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 22px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 400px; will-change: var(--framer-will-change-override, transform); }", ".framer-N50eg .framer-ofkeuz { flex: none; height: 100%; overflow: hidden; position: relative; width: 0%; will-change: var(--framer-will-change-override, transform); }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-N50eg.framer-1vatqyt { gap: 0px; } .framer-N50eg.framer-1vatqyt > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-N50eg.framer-1vatqyt > :first-child { margin-left: 0px; } .framer-N50eg.framer-1vatqyt > :last-child { margin-right: 0px; } }", ".framer-N50eg.framer-v-9vd1pc .framer-ofkeuz { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 22
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"jPz9WwxEu":{"layout":["fixed","fixed"]},"G7sh9WltN":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framervye4J0Sf6: React.ComponentType<Props> = withCSS(Component, css, "framer-N50eg") as typeof Component;
export default Framervye4J0Sf6;

Framervye4J0Sf6.displayName = "Component / Loading Indicator";

Framervye4J0Sf6.defaultProps = {height: 22, width: 400};

addPropertyControls(Framervye4J0Sf6, {variant: {options: ["EfcFBdZXw", "jPz9WwxEu", "G7sh9WltN"], optionTitles: ["Playing", "Complete", "Inactive"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framervye4J0Sf6, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})